import React from "react";
import { graphql } from 'gatsby'
import { resetWebflow } from "../utils/common";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Notification from "../components/header/notification";
import Layout from "../layouts/headerFooterOnly";

export default ({
  data: {
    datoCmsNotFound: { title, subtitle, image, seoMetaTags, notificationPage },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  resetWebflow();
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <div className="main-header about reducedbottom">
        <div className="container center">
          <h1 className="h-fixedwidth520 center">{title}</h1>
          <div className="sm-spacing" />
          <div className="p center-align">
            <ReactMarkdown source={subtitle} escapeHtml={false} />
          </div>
          <div className="sm-spacing" />
          <Link to="/" className="link-text w-inline-block w-clearfix">
            <div className="learn-more">Back to homepage</div>
            <div className="arrow-right" />
          </Link>
          <div className="md-spacing" />
          <div className="_404">
            <img src={image.url} />
          </div>
        </div>
      </div>
    </Layout>    
  );
};

export const query = graphql`
  query NotFoundQuery {
    datoCmsNotFound {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
      image {
        url
      }
      notificationPage {
        id
        page
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
